<template>
  <tr
    :class="(invite.acceptedAt || invite.declinedAt || invite.request) && 'is-clickable'"
    @click="showAcceptedModal = invite.acceptedAt || invite.declinedAt || invite.request"
  >
    <td>
      <label class="label">{{ invite.name }}</label>
      <p class="help has-text-weight-normal">{{ invite.email }}</p>
      <router-link :to="{ name: 'ViewCampaign', params: { campaignId: invite.campaignId }}" class="button is-ghost pl-0 pt-0 is-hidden-tablet">
        {{ invite.campaignName }}
      </router-link>
    </td>
    <td v-if="showCampaign" class="is-hidden-mobile">
      <router-link :to="{ name: 'ViewCampaign', params: { campaignId: invite.campaignId }}" class="button is-info">
        <span>{{ invite.campaignName }}</span>
        <Icon icon="chevron-right" />
      </router-link>
    </td>

    <td v-if="invite.rejectedAt">
      <label class="label">Rejected {{ formatDate(invite.rejectedAt) }}</label>
      <p class="help">requested {{ formatDate(invite.created) }}</p>
    </td>
    <td v-else-if="invite.declinedAt">
      <label class="label">Declined {{ formatDate(invite.declinedAt) }}</label>
      <p class="help">sent {{ formatDate(invite.created) }}</p>
    </td>
    <td v-else-if="!invite.acceptedAt && invite.expiryDate">
      <label class="label" v-if="new Date().getTime() > invite.expiryDate.toDate().getTime()">expired</label>
      <label class="label" v-else>{{ formatDistanceStrict(new Date().getTime(), invite.expiryDate.toDate().getTime()) }} remaining</label>
      <p class="help">{{ invite.request ? 'requested' : 'sent' }} {{ formatDate(invite.created) }}</p>
    </td>
    <td v-else-if="invite.shippedAt">
      <label class="label">Shipped {{ formatDate(invite.shippedAt) }}</label>
      <p v-if="invite.shippedAt" class="help">accepted {{ formatDate(invite.acceptedAt) }}</p>
    </td>
    <td v-else-if="invite.acceptedAt">
      <label class="label">Accepted {{ formatDate(invite.acceptedAt) }}</label>
    </td>
    
    <td v-if="invite.feedbackMessage" class="is-hidden-mobile">
      <div class="box p-2">
        <p>"{{ invite.feedbackMessage }}"</p>
      </div>
    </td>
    <td
      v-if="!invite.request && !invite.acceptedAt && !invite.declinedAt"
      class="has-text-right is-hidden-tablet"
    >
      <Icon icon="fa-solid fa-ellipsis-vertical" class="is-clickable" @click="showOptionsModal = true" />
    </td>
    <td
      v-if="!invite.request && !invite.acceptedAt && !invite.declinedAt"
      colspan="2"
      class="has-text-right is-hidden-mobile"
    >
      <button
        class="button is-info"
        :disabled="reminderSent"
        @click="showReminderModal = true"
      >
        {{ !reminderSent ? 'Send reminder' : 'Reminder sent' }}
      </button>
      <button
        class="button is-ghost"
        :disabled="invite.isCopied"
        @click="copyURL(invite)"
      >
        {{ !invite.isCopied ? 'Copy link' : 'Link copied' }}
      </button>
      <button class="button is-danger" @click="showDeleteModal = true">
        Delete
      </button>
    </td>
    <td
      v-if="invite.acceptedAt || invite.declinedAt || invite.request"
      colspan="2"
      class="has-text-right"
    >
      <Icon icon="chevron-right" class="is-hidden-tablet" />
      <button class="button is-info is-hidden-mobile">
        View Order
      </button>
    </td>
    <td v-if="invite.declinedAt" class="has-text-right">
      <button class="button is-info">
        View Rejection
      </button>
    </td>
  </tr>

  <Teleport to="body">
    <Modal
      v-if="showOptionsModal"
      title="Sent Invite"
      :isSmall="true"
      @close="showOptionsModal = false"
    >
      <div class="columns is-multiline is-mobile">
        <div class="column is-6 field">
          <label class="label">Invite Name</label>
          <p>{{ invite.name }}</p>
        </div>
        <div class="column is-6 field">
          <label class="label">Invite Email</label>
          <p>{{ invite.email }}</p>
        </div>
        <div class="column is-6 field">
          <label class="label">Campaign Name</label>
          <p>{{ invite.campaignName }}</p>
        </div>
        <div class="column is-6 field">
          <label class="label">Expiry Date</label>
          <p>{{ formatDate(invite.expiryDate) }}</p>
          <p class="help">sent {{ formatDate(invite.created) }}</p>
        </div>
      </div>

      <button
        class="button is-info is-fullwidth mb-3"
        :disabled="reminderSent"
        @click="showReminderModal = true"
      >
        {{ !reminderSent ? 'Send reminder' : 'Reminder sent' }}
      </button>
      <button
        class="button is-ghost is-fullwidth mb-3"
        :disabled="invite.isCopied"
        @click="copyURL(invite)"
      >
        {{ !invite.isCopied ? 'Copy link' : 'Link copied' }}
      </button>
      <button class="button is-danger is-fullwidth mb-3" @click="showDeleteModal = true">
        Delete
      </button>
    </Modal>
  </Teleport> 
  
  <Teleport to="body">
    <Modal
      v-if="showReminderModal"
      title="Send Reminder"
      :isSmall="true"
      @close="showReminderModal = false"
    >
      <p>Are you sure you want to send another invite reminder?</p>
      <template v-slot:footer>
        <button class="button is-black" @click="sendReminder(invite)">
          Send
        </button>
        <button class="button" @click="showReminderModal = false">
          Cancel
        </button>
      </template>
    </Modal>
  </Teleport> 
  
  <Teleport to="body">
    <Modal
      v-if="showDeleteModal"
      title="Delete Invite"
      :isSmall="true"
      @close="showDeleteModal = false"
    >
      <p v-html="deleteQuestion"></p>
      <template v-slot:footer>
        <button class="button is-black" @click="doDeleteInvite(invite.id)">
          Delete
        </button>
        <button class="button" @click="showDeleteModal = false">
          Cancel
        </button>
      </template>
    </Modal>
  </Teleport>

  <Teleport to="body">
    <InviteAcceptedModal
      v-if="showAcceptedModal"
      :invite="invite"
      @close="showAcceptedModal = false"
    />
  </Teleport>
</template>

<script>
import { ref, computed } from 'vue'
import getUser from '@/composables/getUser'
import useCollection from '@/composables/useCollection'
import useDocument from '@/composables/useDocument'
import Modal from '@/components/Modals/Modal.vue'
import InviteAcceptedModal from '@/components/Modals/InviteAcceptedModal.vue'
import Icon from '@/components/Icon.vue'
import filters from '@/utils/filters'
import { formatDistanceStrict } from 'date-fns'
import { recipient48HReminderEmail } from '@/utils/mailHandler'

export default {
  props: ['invite', 'showCampaign', 'rootInviteList'],
  components: { Modal, InviteAcceptedModal, Icon },
  setup(props) {
    const { user } = getUser()

    const showOptionsModal = ref(false)
    const showReminderModal = ref(false)
    const showDeleteModal = ref(false)
    const showAcceptedModal = ref(false)
    const reminderSent = ref(false)

    const sendReminder = async (invite) => {
      recipient48HReminderEmail(user, invite, props)

      // Moved to mailHandler
      // const { addDoc: addEmail } = useCollection('mail')
      // await addEmail({
      //   to: invite.email,
      //   templateId: 'd-f02678b92650435abdcc0740458c6f7b', // SWAGIBLE:RECIPIENTINVITE48HREMINDER
      //   dynamic_template_data: {
      //     recipient_first_name: invite.name,
      //     accountHolder_first_name: user.value.displayName,
      //     first_name: user.value.displayName,
      //     campaignExpiryDate: formatDateAsMMMDD(props.campaign.expiryDate.seconds),
      //     swag_url: `${process.env.VUE_APP_BASE_URL}/invite/${invite.campaignId}/${invite.id}`,
      //     decline_swag_url: `${process.env.VUE_APP_BASE_URL}/invite/${invite.campaignId}/${invite.id}?showDecline=true`
      //   }
      // })
      reminderSent.value = true
      showReminderModal.value = false
    }

    const deleteQuestion = computed(() => {
      if (props.rootInviteList != null && props.rootInviteList.length == 1) {
        return "Are you sure you want to delete this invite?<br><br>Deleting the last recipient will automatically cancel your campaign."
      } else {
        return "Are you sure you want to delete this invite? "
      }
    })

    const copyURL = (invite) => {
      const link = `${window.location.origin}/invite/${invite.campaignId}/${invite.id}`
      var TempText = document.createElement('input')
      TempText.value = link
      document.body.appendChild(TempText)
      TempText.select()
      document.execCommand('copy')
      document.body.removeChild(TempText)      
      invite.isCopied = true
      
      setTimeout(() => invite.isCopied = false, 3000)
    }

    const doDeleteInvite = async (inviteId) => {
      const { deleteDoc: deleteInvite } = useDocument('invites', inviteId)      
      await deleteInvite()
      showDeleteModal.value = false
    }

    return {
      showOptionsModal,
      showReminderModal,
      showDeleteModal,
      showAcceptedModal,

      deleteQuestion,
      sendReminder,
      reminderSent,
      copyURL,
      doDeleteInvite,

      ...filters,
      formatDistanceStrict
    }
  }
}
</script>

<style scoped>
.is-clickable:hover {
  background-color: #fafafa;
}

.has-text-right.is-hidden-mobile {
  white-space: nowrap;
}

.table .label {
  white-space: wrap;
}

.table td:last-child {
  padding-right: 6px;
}

@media screen and (max-width: 768px) {

.table td:first-child * {
  max-width: 48vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
}
}
</style>