<template>
  <!-- <nav class="panel">
    <p class="panel-tabs">
      <a
        v-for="item in options"
        :key="item"
        :class="item.value === modelValue && 'is-active'"
        @click="$emit('update:modelValue', item.value)"
      >
        {{ item.label }}
      </a>
    </p>
  </nav> -->

  <div class="tabs">
    <ul>
      <li
        v-for="item in options"
        :key="item"
        :class="item.value === modelValue && 'is-active'"
        @click="$emit('update:modelValue', item.value)"
      >
        <a>
          {{ item.label }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['options', 'modelValue'],
}
</script>