<template>
  <div :class="`modal ${isSmall && 'is-small modal-fx'} ${showModal && 'is-active'}`">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-card">
      <header v-if="title" class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
      </header>

      <section class="modal-card-body" :style="`padding-bottom: calc(env(safe-area-inset-bottom) + 40px + ${buttonCount * 50}px)`">
        <slot></slot>
      </section>

      <footer :class="`modal-card-foot ${!isSmall && 'is-fixed'} `">
        <slot name="footer"></slot>
      </footer>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'

export default {
  props: ['title', 'isSmall', 'buttonCount'],
  emits: ['close'],
  setup() {
    const showModal = ref(false)
    setTimeout(() => showModal.value = true, 100)

    onMounted(() => {
      document.getElementsByTagName('body')[0].style.height = '100vh'
    })

    onUnmounted(() => {
      document.getElementsByTagName('body')[0].style.height = 'auto'
    })

    return {
      showModal,
    }
  }
}
</script>

<style scoped>
.modal-card-body {
  text-wrap: wrap;
}
</style>
