<template>
  <Modal
    :title="invite.name"
    @close="$emit('close')"
  >
    <div v-if="invite.feedbackMessage" class="field">
      <label class="subtitle is-size-6">
        Message
      </label>
      <p>{{ invite.feedbackMessage }}</p>
    </div>

    <div v-if="invite.chosenProducts" class="field mt-5">
      <h3 class="subtitle is-size-6">Items</h3>

      <OrderItem
        v-for="product in invite.chosenProducts"
        :key="product.id"
        :item="product"
      />
    </div>

    <div v-if="inviteTotal" class="field mt-5">
      <label class="subtitle is-size-6">
        Total
        <span class="is-size-3" v-html="formatCurrency(inviteTotal, 'CAD')" />
      </label>
    </div>

    <template v-slot:footer>
      <button
        v-if="invite.request && !invite.rejectedAt"
        class="button is-danger"
        @click="rejectRequest()"
      >
        Reject
      </button> 
      <button
        v-if="invite.request && !invite.acceptedAt"
        class="button is-info"
        @click="acceptRequest()"
      >
        Approve
      </button>
      <button
        :class="`button ${(invite.acceptedAt || invite.rejectedAt) && 'is-black'}`"
        @click="$emit('close')"
      >
        Close
      </button>
    </template>
  </Modal>
</template>

<script>
import { computed } from 'vue'
import useDocument from '@/composables/useDocument'
import Modal from '@/components/Modals/Modal.vue'
import OrderItem from '@/components/Campaign/OrderItem.vue'
import { timestamp, deleteField } from '@/firebase/config'
import filters from '@/utils/filters'

export default {
  props: ['invite'],
  emits: ['close'],
  components: { Modal, OrderItem },
  setup(props, { emit }) {
    const inviteTotal = computed(() => {
      return props.invite.chosenProducts.reduce((acc, { charge }) => acc + Number(charge), 0)
    })

    const acceptRequest = async () => {
      const { updateDoc: updateInvite } = useDocument('invites', props.invite.id)      
      await updateInvite({
        acceptedAt: timestamp(),
        rejectedAt: deleteField()
      })
      emit('close')
    }

    const rejectRequest = async () => {
      const { updateDoc: updateInvite } = useDocument('invites', props.invite.id)      
      await updateInvite({
        acceptedAt: deleteField(),
        rejectedAt: timestamp()
      })
      emit('close')
    }

    return {
      inviteTotal,
      acceptRequest,
      rejectRequest,
      ...filters
    }
  }
}
</script>